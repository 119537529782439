<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw', position: 'relative' }"
      :header="'Alterar Probabilidade de Fecho da Lead'"
      :modal="true"
      :closable="false"
    >
      <form
        name="CRMChangepClosingProbability"
        class="p-col-12"
        @submit.prevent="setPriority"
        style="min-height: 15vh"
      >
        <Loading
          :active.sync="showLoader"
          :isFullPage="false"
          :zIndex="9000"
        ></Loading>
        <p v-if="!selected">
          <b>Lead {{ proposalId }} | {{ proposalSubject }}</b>
        </p>
        <p
          v-else
          v-for="proposalSelected in selected"
          :key="proposalSelected.id"
        >
          <b>Lead {{ proposalSelected.id }} | {{ proposalSelected.subject }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="proposalClosingProbability"
                :options="closingProbabilityList"
                :filter="true"
                :optionLabel="'label'"
                :optionValue="'value'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('proposalPriority') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="proposalPriority"
                >Selecionar Probabilidade de Fecho da Lead</label
              >
            </span>
            <small
              v-if="errors.has('proposalClosingProbability')"
              class="p-error"
              role="alert"
            >
              Probabilidade de Fecho é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setClosingProbability"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import crmService from "../../services/crm.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "CrmChangeClosingProbability",
  components: {
    Loading,
  },
  props: [
    "showToast",
    "show",
    "closingProbability",
    "proposalId",
    "proposalSubject",
    "selected",
  ],
  watch: {
    show() {
      this.showLoader = false;
      this.$validator.pause();
      this.$validator.reset();
      this.current =
        !this.selected || this.selected == undefined
          ? this.closingProbability
          : null;
    },
  },
  data() {
    return {
      current: !this.selected ? this.closingProbability : null,
      closingProbabilityList: [
        { label: "0%", value: 0 },
        { label: "15%", value: 15 },
        { label: "25%", value: 25 },
        { label: "50%", value: 50 },
        {
          label: "75%",
          value: 75,
        },
        { label: "90%", value: 90 },
        { label: "100%", value: 100 },
      ],
      showLoader: false,
    };
  },
  created() {},
  methods: {
    cancel() {
      return this.$emit("cancel");
    },
    setClosingProbability() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.selected != null) {
          return this.setClosingProbabilityMultipleProposals();
        }
        this.showLoader = true;
        return crmService
          .setClosingProbability(this.proposalId, {
            closing_probability: parseInt(this.current),
          })
          .then((response) => {
            this.showLoader = false;
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar a probabilidade de fecho da lead",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("changedProposalClosingProbability", {
                status: "error",
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Prioridade da lead alterada com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("changedProposalClosingProbability", {
              status: "ok",
              result: response,
            });
          });
      });
    },
    async setClosingProbabilityMultipleProposals() {
      let noErrors = true;
      let res = null;
      this.showLoader = true;
      for (const proposal of this.selected) {
        await crmService
          .setClosingProbability(proposal.id, {
            closing_probability: parseInt(this.current),
          })
          .then((response) => {
            if (!response) {
              return (noErrors = false);
            }
            res = response;
          });
      }

      this.showLoader = false;
      if (!noErrors) {
        if (this.showToast) {
          this.$toast.add({
            severity: "error",
            summary: "Erro ao alterar a prioridade da lead",
            detail: "",
            life: 3000,
          });
        }
        return this.$emit("changedProposalClosingProbability", {
          status: "error",
        });
      }
      if (this.showToast) {
        this.$toast.add({
          severity: "success",
          summary: `Prioridade da lead alterada com sucesso`,
          detail: ``,
          life: 3000,
        });
      }
      return this.$emit("changedProposalClosingProbability", {
        status: "ok",
        result: res,
      });
    },
  },
};
</script>
